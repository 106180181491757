/* Tooltip container */
.tooltip-container {
    position: relative;
    display: inline;
  }
  
  /* Tooltip text */
  .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 0%; 
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
  }
  
  /* Tooltip arrow */
  .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  /* Show tooltip text on hover */
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    bottom: 125%;
  }
  